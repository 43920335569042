import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import Cookies from 'js-cookie'
import { Token } from '@/utils/constants'
import { isMobile } from '@/utils'
import { AppState, Device, RootState } from '../types'
import { pingServer } from '@/api'

export enum AppMutation {
    SHOW_LOADING = 'SHOW_LOADING',
    HIDE_LOADING = 'HIDE_LOADING',
    SET_INIT_SOUND = 'SET_INIT_SOUND',
    SET_FULL_SCREEN = 'SET_FULL_SCREEN',
    SET_CHIP_VALAUE = 'SET_CHIP_VALAUE',
    SET_SOUND = 'SET_SOUND',
    SET_CHIP_INDEX = 'SET_CHIP_INDEX'
}

export enum AppActions {
    PING_SERVER = 'pingServer'
}

const state: AppState = {
    device: {
        display: isMobile() ? 1 : 0,
        // display: 0,
        size: { width: '', height: '' }
    },
    infoKey: Date.now(),
    customChipValue: parseInt(Cookies.get(Token.CUSTOM_CHIP_VALUE) || '') || 0,
    showLoading: false,
    userInteraction: 0,
    playSound: parseInt(Cookies.get(Token.SOUND) || '') || 1,
    isFullScreen: false,
    currentChipIndex:
        parseInt(Cookies.get(Token.CURRENT_CHIP_INDEX) || '') || -1
}

const mutations: MutationTree<AppState> = {
    [AppMutation.HIDE_LOADING](state) {
        state.showLoading = false
    },
    [AppMutation.SHOW_LOADING](state) {
        state.showLoading = true
    },
    [AppMutation.SET_INIT_SOUND](state) {
        state.userInteraction = 1
    },
    [AppMutation.SET_FULL_SCREEN](state, isFull: boolean) {
        state.isFullScreen = isFull
    },
    [AppMutation.SET_CHIP_VALAUE](state, value: number) {
        state.customChipValue = value
        Cookies.set(Token.CUSTOM_CHIP_VALUE, value.toString())
    },
    [AppMutation.SET_SOUND](state, num: number) {
        state.playSound = num
        Cookies.set(Token.SOUND, num.toString())
    },
    [AppMutation.SET_CHIP_INDEX](state, value: number) {
        state.currentChipIndex = value
        Cookies.set(Token.CURRENT_CHIP_INDEX, value.toString())
    }
}

const actions: ActionTree<AppState, RootState> = {
    showLoading({ commit }): void {
        commit(AppMutation.SHOW_LOADING)
    },
    hideLoading({ commit }): void {
        commit(AppMutation.HIDE_LOADING)
    },
    interActPage({ commit }): void {
        commit(AppMutation.SET_INIT_SOUND)
    },
    setFullScreen({ commit }, isFull: boolean): void {
        commit(AppMutation.SET_FULL_SCREEN, isFull)
    },
    setCustomChip({ commit }, value: number): void {
        commit(AppMutation.SET_CHIP_VALAUE, value)
    },
    setSound({ commit }, num: number) {
        commit(AppMutation.SET_SOUND, num)
    },
    setCurrentChipIndex({ commit }, chipIndex: number): void {
        commit(AppMutation.SET_CHIP_INDEX, chipIndex)
    },
    [AppActions.PING_SERVER](
        { commit },
        params: { line: number; url: string }
    ): Promise<unknown> {
        return new Promise<unknown>((resolve, reject) => {
            const { line, url } = params

            pingServer(line, url)
                .then(response => {
                    resolve(response.data)
                })
                .catch(error => {
                    console.log(error)
                    reject(error)
                })
        })
    }
}

const getters: GetterTree<AppState, RootState> = {
    device(state): Device {
        return state.device
    },
    isLoading(state): boolean {
        return state.showLoading
    },
    isSoundOn(state): boolean {
        return state.playSound === 1
    },
    userHasInteractToPage(state): boolean {
        return state.userInteraction !== 0
    },
    customChipValue(state): number {
        return state.customChipValue
    },
    isFullScreen(state): boolean {
        return state.isFullScreen
    },
    soundEffect(state): number {
        return state.playSound
    },
    currentChipIndex(state): number {
        return state.currentChipIndex
    }
}

export const app: Module<AppState, RootState> = {
    state,
    mutations,
    actions,
    getters
}
