export const Token = {
    USERNAME: 'us_id',
    SESSION: 'us_ss_id',
    LIMITS: 'us_tb_lmt',
    BET_COIN: 'bt_con',
    LOBY_NOTICE: 'lb_ntce',
    DESK_NOTICE: 'dk_ntce',
    DESK_LIMIT: 'dsk_lmt',
    CHIPS_VALUES: 'chips_values',
    DESK_CURRENT: 'current_desk',
    SOUND: 'config_sound',
    MULTIBET_DESK: 'multi_desks_bet',
    CURRENT_CHIP_INDEX: 'c_cph_idx',
    CUSTOM_CHIP_VALUE: 'c_cph_vl',
    LINE: 'c_line',
    LINE_CUSTOM: 'c_line_custom'
}

/**
 * API ACT params
 */
export const ACT = {
    LOGIN: 1,
    TRIAL: 11,
    LOG_OUT: 17,
    MULTIPLE_DESK_INFO: 2,
    GET_NOTICE_AND_LIMIT: 9,
    SINGLE_DESK_INFO: 3,
    CARD_INFO: 10,
    DO_DT_BET: 7,
    DO_BAC_BET: 6,
    CHANGE_PASS: 12,
    GET_LOG: 13,
    GET_LOG_DETAILS: 15,
    MULTIPLE_BET_DESK_INFO: 18
}

export const Color = {
    RED: 1,
    BLUE: 2
}

/**
 * Determin the game type
 */
export const GAME_TYPE = {
    BACCARAT: 1,
    DRAGON_TIGER: 2
}

/**
 * BET TYPE
 * Determin the type of Bet
 */
export const BET_TYPE = {
    BANKER: 0,
    PLAYER: 1,
    BC_TIE: 2,
    DT_TIE: 3,
    DRAGON: 4,
    TIGER: 5,
    PLAYER_PAIR: 6,
    BANKER_PAIR: 7,
    BANKER_SUPER6: 8,
    SUPER6: 9
}

/**
 * GameState Of a Desk
 */
export const GameRoundState = {
    CLOSED: 1,
    END_OF_ROUND: 4
}

/**
 * Game Result
 */
export const RESULT = {
    RED: 1,
    BLUE: 3,
    GREEN: 2
}

export enum EventBuss {
    SHOW_POP_ALERT = 'SHOW_POP_ALERT',
    RELOAD_MAP = 'RELOAD_MAP',
    ORIENTATION_CHANGE = 'ORIENTATION_CHANGE',
    ON_ADD_TO_HOME_PAGE = 'ON_ADD_TO_HOME_PAGE',
    SHOW_NUMERIC_KEYBOARD = 'SHOW_NUMERIC_KEYBOARD'
}

/**
 * Determine the bet error
 */
export enum BetError {
    TIE_MAX_LIMIT = '超出和最大限红',
    BALANCE_SHORT = '余额不足',
    BELOW_MINIMUM = '小于最低限红',
    ABOVE_PAIR_LIMIT = '超出对子最大限红',
    SUPER6_ABOVE_LIMIT = '超出超级6最大限红',
    PALYER_BANKER_ABOVE_LIMIT = '超出庄闲最大限红',
    DRAGON_TIGER_ABOVE_LIMIT = '超出虎龙最大限红',
    ABOVE_MAXIMUM = '超过最大限红',
    BET_OK = 'OK',
    UNKNOWN_ERROR = 'Unknown Error',
    NO_CHIPS_SELECTED = '请先选择筹码再投注'
}

// /**
//  * Determine the bet error
//  */
// export enum BetError {
//     TIE_MAX_LIMIT = 'TIE_MAX_LIMIT',
//     BALANCE_SHORT = 'BALANCE_SHORT',
//     BELOW_MINIMUM = 'BELOW_MINIMUM',
//     ABOVE_PAIR_LIMIT = 'ABOVE_PAIR_LIMIT',
//     SUPER6_ABOVE_LIMIT = 'SUPER6_ABOVE_LIMIT',
//     PALYER_BANKER_ABOVE_LIMIT = 'PALYER_BANKER_ABOVE_LIMIT',
//     DRAGON_TIGER_ABOVE_LIMIT = 'DRAGON_TIGER_ABOVE_LIMIT',
//     ABOVE_MAXIMUM = 'ABOVE_MAXIMUM',
//     BET_OK = 'OK',
//     UNKNOWN_ERROR = 'Unknown Error',
//     NO_CHIPS_SELECTED = 'NO_CHIPS_SELECTED'
// }
