import { getCookieValue } from '@/api'
import axios from 'axios'
import Cookies from 'js-cookie'
import { Token } from './constants'
const service = axios.create({
    baseURL: process.env.API_URL + process.env.API_EXT,
    timeout: 5000
})

// request interceptor
service.interceptors.request.use(
    config => {
        const line = parseInt(getCookieValue(Token.LINE) ?? '0')

        if (line > 0) {
            if (line === 1) {
                config.baseURL = `${process.env.API_LINE1}/${process.env.API_EXT}`
            } else if (line === 2) {
                config.baseURL = `${process.env.API_LINE2}/${process.env.API_EXT}`
            } else if (line === 3) {
                config.baseURL = `${process.env.API_LINE3}/${process.env.API_EXT}`
            }
        } else if (line === -1) {
            const customIp = Cookies.get(Token.LINE_CUSTOM)
            config.baseURL = `${customIp}/${process.env.API_EXT}`
        }
        return config
    },
    error => {
        console.log(error)
        return Promise.reject(error)
    }
)

// request response interceptor
service.interceptors.response.use(
    response => {
        return response
    },
    error => {
        console.log(`error: ${error}`) // debug error
        return Promise.reject(error)
    }
)

export default service
