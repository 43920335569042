
import { Mixins } from 'vue-property-decorator'
import { RoadMapData } from '../roadmap/data-model'
import { LobyListItemMixin } from './list-item-mixin'

export default class MobileListItem extends Mixins(LobyListItemMixin) {
    roadMapData: RoadMapData = new RoadMapData()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    OnRoadMapData(a: RoadMapData, b: RoadMapData, c: RoadMapData): void {
        this.roadMapData = a
    }

    get redCount(): number {
        if (this.roadMapData !== undefined) return this.roadMapData.redCount
        return 0
    }

    get redLimit(): string {
        return `${this.deskData.limit.min} - ${this.deskData.limit.max}`
    }
}
