import router from '@/router'
import { store } from '@/store'

const whiteList = ['/login', '/select-server-line']

router.beforeEach(async (to, from, next) => {
    const { sessionId, userId } = store.state.user

    const isLogin = (sessionId && userId) !== undefined

    if (isLogin) {
        if (to.path === '/login') {
            next({ path: '/' })
        } else {
            next()
        }
    } else {
        if (whiteList.indexOf(to.path) !== -1) {
            next()
        } else {
            next('/select-server-line')
        }
    }
})
