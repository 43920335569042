import { createVNode as _createVNode, resolveComponent as _resolveComponent, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_switch_control = _resolveComponent("switch-control")

  return (_openBlock(), _createBlock("div", {
    class: "setting-dialog-view-root",
    onClick: _cache[7] || (_cache[7] = _withModifiers(($event: any) => (_ctx.$emit('on-close')), ["self"]))
  }, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", {
        class: ["menu", { desktop: _ctx.device.display !== 1 }],
        onClick: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["stop"]))
      }, [
        _createVNode("div", {
          class: "menu-item",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.OnClickRules && _ctx.OnClickRules(...args)))
        }, "游戏规则"),
        _createVNode("div", {
          class: "menu-item",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.OnCLickHistory()))
        }, "游戏记录"),
        _createVNode("div", {
          class: "menu-item",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.OnClickChangePassword && _ctx.OnClickChangePassword(...args)))
        }, " 修改密码 "),
        _createVNode("div", {
          class: "menu-item",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.OnClickCustumerService()))
        }, " 线上客服 "),
        _createVNode(_component_switch_control, {
          class: "switch-control",
          label: '声音效果',
          value: _ctx.soundEffect,
          onChangeValue: _ctx.OnChangeSoundConfig
        }, null, 8, ["value", "onChangeValue"]),
        _createVNode("div", {
          class: "menu-item",
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.ClickLogOut()))
        }, "登出")
      ], 2)
    ])
  ]))
}