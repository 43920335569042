
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'

@Options({})
export default class CalendarPicker extends Vue {
    date: Date | null = null

    @Prop({ default: true })
    enabled!: boolean

    @Prop({ default: '' })
    placeHolder!: string

    @Prop({ default: null })
    value!: Date

    attrs = [
        {
            key: 'today',
            highlight: {
                color: 'purple',
                fillMode: 'outline',
                contentClass: 'italic'
            },
            dates: new Date()
        }
    ]

    beforeMount(): void {
        if (this.value) this.date = this.value
    }

    @Watch('date', { immediate: false })
    onDateChange(value: Date | null): void {
        if (value) {
            this.$emit('on-change', value)
        }
    }
}
