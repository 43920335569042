import { AxiosPromise } from 'axios'
import service from '@/utils/request'
import { DeskMoreInfoModel } from '@/datamodel/urlparams/DeskMoreInfoModel'
import { DeskInfpParams } from '@/datamodel/urlparams/DeskInfoParams'
import { CardInfoParams } from '@/datamodel/urlparams/CardInfoParams'
import { DTBetParams } from '@/datamodel/urlparams/DTBetParams'
import { BaccaratBetParams } from '@/datamodel/urlparams/BaccaratBetParams'

export function getDeskMoreInfo(
    model: DeskMoreInfoModel
): AxiosPromise<string> {
    return service({
        method: 'POST',
        params: model
    })
}

export function getDeskInfo(model: DeskInfpParams): AxiosPromise<string> {
    return service({
        method: 'POST',
        params: model
    })
}

/**
 * Get Card Info
 * @param model
 */
export function getCardInfo(model: CardInfoParams): AxiosPromise<string> {
    return service({
        method: 'POST',
        params: model
    })
}

/**
 * Place Bet On Dragon tiger Api
 * @param model
 */
export function placeDragonTigerBet(model: DTBetParams): AxiosPromise<string> {
    return service({
        method: 'POST',
        params: model
    })
}

/**
 * Place Bet On Baccarat Api
 * @param model
 */
export function placeBaccaratBet(
    model: BaccaratBetParams
): AxiosPromise<string> {
    return service({
        method: 'POST',
        params: model
    })
}
