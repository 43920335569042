
import SwitchControl from '@/components/switch-control.vue'
// import { Dialog, EventBus, EventBusEvents } from '@/event/EventBus'
import { Action, Getter } from 'vuex-class'
import { Device } from '@/store/types'
import audioPlayer from '@/utils/sounds'
import { Options, Vue } from 'vue-class-component'

@Options({
    components: {
        SwitchControl
    }
})
export default class SettingDialogView extends Vue {
    @Getter('device') device!: Device
    @Getter('userId') uid!: string
    @Getter('soundEffect') soundEffect!: number

    @Action('logOut') logOut!: (uname: string) => Promise<void>
    @Action('setSound') setSound!: (num: number) => void
    @Action('showLoading') showLoading!: () => void
    @Action('hideLoading') hideLoading!: () => void

    // @Action('destroySession') destroySession!: () => void

    ClickLogOut(): void {
        // EventBus.$emit(EventBusEvents.SHOW_LOADER, true)
        this.showLoading()
        this.logOut(this.uid)
            .then(() => {
                // EventBus.$emit(EventBusEvents.SHOW_LOADER, false)
                this.hideLoading()
                this.$emit('on-close')
                this.$router.push({ name: 'login' })
                // this.destroySession()
            })
            .catch(() => {
                setTimeout(() => {
                    this.ClickLogOut()
                }, 1000)
            })
    }

    OnClickChangePassword(): void {
        this.$emit('on-show-change-password-form')
        this.$emit('on-close')
    }

    OnCLickHistory(): void {
        this.$emit('on-show-history')
        this.$emit('on-close')
    }

    OnChangeSoundConfig(num: number): void {
        if (audioPlayer) audioPlayer.isOn = num
        this.setSound(num)
    }
    onClickOutside(): void {
        // EventBus.$emit(EventBusEvents.SHOW_DIALOGS, Dialog.SETTING)
    }
}
