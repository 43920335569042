
import { Vue, Options } from 'vue-class-component'
import { Getter, Action } from 'vuex-class'
import audioPlayer from '@/utils/sounds'
import { Device } from '@/store/types'
import { Watch } from 'vue-property-decorator'
import screenfull from 'screenfull'
import { EventBuss } from './utils/constants'
import NumnericKeyboard from '@/components/numeric-virtual-keyborad.vue'
import { AppMutation } from './store/module/app'

@Options({
    components: {
        NumnericKeyboard
    }
})
export default class App extends Vue {
    message!: string
    isMobile = false

    initialWidth = 0
    initialHeight = 0

    resizeTimerFlag: number | boolean = false

    isFirstLoad = true

    showNumericKeyboard = false

    //#region Vuex
    @Getter('device') device!: Device
    @Getter('userHasInteractToPage') interactedToPage!: boolean
    @Getter('isSoundOn') isSoundOn!: boolean
    @Getter('isLoading') isLoading!: boolean
    @Getter('isFullScreen') isFullScreen!: boolean

    @Action('interActPage') interActToPage!: () => void
    @Action('setFullScreen') setFullScreen!: (isFull: boolean) => void
    //#endregion

    //#region Vue Methods
    beforeMount(): void {
        window.addEventListener('resize', this.handleResizeWindow)
        this.isMobile = this.device.display === 1

        // this will remove the distored size on focus input in mobile
        if (this.isMobile) {
            this.initialWidth = window.innerWidth
            this.initialHeight = window.innerHeight

            const viewport = document.querySelector('meta[name=viewport]')
            viewport?.setAttribute(
                'content',
                'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'
            )

            // window.addEventListener(
            //     'orientationchange',
            //     this.onOrientationChange
            // )
        }

        document.addEventListener('fullscreenchange', this.fullScreenChange)
        document.addEventListener(
            'webkitfullscreenchange',
            this.fullScreenChange
        )
        document.addEventListener('mozfullscreenchange', this.fullScreenChange)
        document.addEventListener('MSFullscreenChange', this.fullScreenChange)
    }

    mounted(): void {
        if (!audioPlayer.isLoaded) {
            audioPlayer.isOn = this.isSoundOn ? 1 : 0
            audioPlayer.LoadSounds()
        }

        // 744 x 360
        // 324 x 744
        // alert(document.body.getBoundingClientRect().width)
        // if (!this.isMobile) {
        this.handleResizeWindow()
        // }
        if (this.device.display === 1) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            this.emitter.on(EventBuss.ON_ADD_TO_HOME_PAGE, () => {
                // add to home screen code here
            })
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            this.emitter.on(
                EventBuss.SHOW_NUMERIC_KEYBOARD,
                this.handlesShowOrHideNumericKeyboard
            )
        }
    }

    //#endregion

    //#region Helpers

    initSound(): void {
        // check if a user interaction is done
        //  if not then need to initialize sound
        if (!this.interactedToPage) {
            this.interActToPage()
            audioPlayer.Start()
        }
    }

    fullScreenChange(): void {
        if (
            !document.fullscreenElement &&
            !document.webkitIsFullScreen &&
            !document.mozFullScreen &&
            !document.msFullscreenElement
        ) {
            ///fire your event
            this.setFullScreen(false)
        }
    }

    onConfirmNumericKeyboard(_inputValue: number): void {
        this.showNumericKeyboard = false
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        this.$store.commit(AppMutation.SET_CHIP_VALAUE, _inputValue)
    }

    handlesShowOrHideNumericKeyboard(_showOrHide: boolean): void {
        this.showNumericKeyboard = _showOrHide
    }

    //#endregion

    //#region Events

    onOrientationChange(): void {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        this.emitter.emit(EventBuss.ORIENTATION_CHANGE)
    }

    handleResizeWindow(): void {
        if (!this.isMobile) {
            const pcMain = document.getElementById('pc-main')
            const body = document.getElementsByTagName('html')[0] as HTMLElement
            if (pcMain && body)
                body.style.fontSize =
                    (pcMain?.getBoundingClientRect().width / 20 / 16 / 8) * 19 +
                    'px'
            // console.log('override font')
        }

        if (typeof this.resizeTimerFlag === 'number') {
            clearTimeout(this.resizeTimerFlag)
            this.resizeTimerFlag = false
        }

        this.resizeTimerFlag = setTimeout(() => {
            if (this.isFirstLoad) {
                this.isFirstLoad = false
            } else {
                if (this.device.display === 1) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    this.emitter.emit(EventBuss.ORIENTATION_CHANGE)
                }

                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                this.emitter.emit(EventBuss.RELOAD_MAP)
            }
        }, 250)
    }
    //#endregion

    @Watch('isFullScreen')
    OnFullScreenChange(value: boolean): void {
        if (value && this.device.display !== 1) {
            const docEl = document.getElementById('pc-main') as HTMLElement
            if (docEl && screenfull.isEnabled) {
                // beFull(docEl)
                screenfull.request(docEl)
            } else {
                console.log('cant full screen')
            }
        }
    }
}
