import { DeskState, RootState } from '../types'
import Cookies from 'js-cookie'
import { Token } from '@/utils/constants'
import { DeskData } from '@/datamodel/deskdata'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { TableLimit } from '@/datamodel/types'
import { DeskInfpParams } from '@/datamodel/urlparams/DeskInfoParams'
import { ResponseData } from '@/datamodel/response-data'
import {
    getCardInfo,
    getDeskInfo,
    getDeskMoreInfo,
    placeBaccaratBet,
    placeDragonTigerBet
} from '@/api/desk'
import { getValueByKey } from '@/utils'
import { DeskMoreInfoModel } from '@/datamodel/urlparams/DeskMoreInfoModel'
import { Dictionary } from '@/datamodel/dictionary'
import { CardInfoParams } from '@/datamodel/urlparams/CardInfoParams'
import { DTBetParams } from '@/datamodel/urlparams/DTBetParams'
import { BaccaratBetParams } from '@/datamodel/urlparams/BaccaratBetParams'

export enum DeskMutation {
    SET_DESK_NOTICE = 'SET_DESK_NOTICE',
    SET_DESK_LIMIT = 'SET_DESK_LIMIT',
    SET_CHIP_VALUES = 'SET_CHIP_VALUES',
    SET_CURRENT_DESK = 'SET_CURRENT_DESK',
    SET_CARD_RESULT_CACHE = 'SET_CARD_RESULT_CACHE',
    SET_DESK_INFO_KEY = 'SET_DESK_INFO_KEY'
}

const state: DeskState = {
    deskLimit:
        Cookies.get(Token.DESK_LIMIT)
            ?.b64decode()
            .toObject<TableLimit>() || undefined,
    notice: Cookies.get(Token.DESK_NOTICE)?.b64decode() || '',
    chipsValues:
        Cookies.get(Token.CHIPS_VALUES)
            ?.b64decode()
            .toObject<Array<number>>() || new Array<number>(),
    desk:
        Cookies.get(Token.DESK_CURRENT)
            ?.b64decode()
            .toObject<DeskData>() || undefined,
    cardResults: new Dictionary<string>(),
    deskInfoKey: new Date().getDate()
}

const mutations: MutationTree<DeskState> = {
    [DeskMutation.SET_DESK_NOTICE](state, notice: string) {
        Cookies.set(Token.DESK_NOTICE, notice.b64encode())
        state.notice = notice
        state.deskInfoKey = new Date().getDate()
    },
    [DeskMutation.SET_DESK_LIMIT](state, limit: TableLimit) {
        Cookies.set(Token.DESK_LIMIT, JSON.stringify(limit).b64encode())
        state.deskLimit = limit
    },
    [DeskMutation.SET_CHIP_VALUES](state, chipValues: Array<number>) {
        Cookies.set(Token.CHIPS_VALUES, JSON.stringify(chipValues).b64encode())
        state.chipsValues = chipValues
    },
    [DeskMutation.SET_CURRENT_DESK](state, desk: DeskData | undefined) {
        if (desk) {
            Cookies.set(Token.DESK_CURRENT, JSON.stringify(desk).b64encode())
        } else {
            Cookies.remove(Token.DESK_CURRENT)
        }

        state.desk = desk
    },
    [DeskMutation.SET_CARD_RESULT_CACHE](
        state,
        cardResultsCache: Dictionary<string>
    ) {
        state.cardResults = cardResultsCache
    },
    [DeskMutation.SET_DESK_INFO_KEY](state) {
        state.deskInfoKey = new Date().getDate()
    }
}

const actions: ActionTree<DeskState, RootState> = {
    setDeskLimit({ commit }, limit: TableLimit): void {
        commit(DeskMutation.SET_DESK_LIMIT, limit)
    },
    getDeskInfo({ commit }, model: DeskInfpParams): Promise<ResponseData> {
        return new Promise<ResponseData>((resolve, reject) => {
            getDeskInfo(model)
                .then(response => {
                    if (response.data.includes('err=')) {
                        resolve(
                            new ResponseData({
                                error:
                                    getValueByKey(response.data, 'err') || '2'
                            })
                        )
                    } else {
                        resolve(
                            new ResponseData({
                                data: response.data,
                                optional: model.desk
                            })
                        )
                    }
                })
                .catch(() => reject())
        })
    },
    getDeskOtherInfo(
        { commit },
        model: DeskMoreInfoModel
    ): Promise<ResponseData> {
        return new Promise<ResponseData>((resolve, reject) => {
            getDeskMoreInfo(model)
                .then(response => {
                    if (response.data.includes('err=')) {
                        resolve(
                            new ResponseData({
                                error:
                                    getValueByKey(response.data, 'err') || '2'
                            })
                        )
                    } else {
                        resolve(
                            new ResponseData({
                                data: response.data
                            })
                        )
                    }
                })
                .catch(() => reject())
        })
    },
    setDeskNotice({ commit }, notice: string): void {
        commit(DeskMutation.SET_DESK_NOTICE, notice)
    },
    setChipsValues({ commit }, chipValues: Array<number>): void {
        commit(DeskMutation.SET_CHIP_VALUES, chipValues)
    },
    setCurrentDesk({ commit }, desk: DeskData): void {
        commit(DeskMutation.SET_CURRENT_DESK, desk)
    },
    getCardInfo({ commit }, model: CardInfoParams): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            getCardInfo(model)
                .then(response => {
                    const data = response.data
                    const err = getValueByKey(data, 'err')
                    if (err) {
                        resolve('err=2')
                    } else {
                        resolve(data)
                    }
                })
                .catch(err => {
                    console.log(err)
                    reject(err)
                })
        })
    },
    setCardResultCache({ commit }, model: Dictionary<string>) {
        commit(DeskMutation.SET_CARD_RESULT_CACHE, model)
    },
    placeDragontigerBet({ commit }, model: DTBetParams): Promise<ResponseData> {
        return new Promise<ResponseData>((resolve, reject) => {
            placeDragonTigerBet(model)
                .then(response => {
                    if (response.data.includes('err=')) {
                        resolve(
                            new ResponseData({
                                error:
                                    getValueByKey(response.data, 'err') || '2'
                            })
                        )
                    } else {
                        resolve(
                            new ResponseData({
                                data: response.data
                            })
                        )
                    }
                })
                .catch(() => {
                    reject('网络错误')
                })
        })
    },
    placeBaccaratBet(
        { commit },
        model: BaccaratBetParams
    ): Promise<ResponseData> {
        return new Promise<ResponseData>((resolve, reject) => {
            placeBaccaratBet(model)
                .then(response => {
                    if (response.data.includes('err=')) {
                        resolve(
                            new ResponseData({
                                error:
                                    getValueByKey(response.data, 'err') || '2'
                            })
                        )
                    } else {
                        resolve(
                            new ResponseData({
                                data: response.data
                            })
                        )
                    }
                })
                .catch(() => {
                    reject('网络错误')
                })
        })
    },
    setDeskInfoKey({ commit }): void {
        commit(DeskMutation.SET_DESK_INFO_KEY)
    }
}

const getters: GetterTree<DeskState, RootState> = {
    deskNotice(state): string {
        return state.notice
    },
    chips(state): Array<number> {
        return state.chipsValues
    },
    deskLimit(state): TableLimit | undefined {
        return state.deskLimit
    },
    currentDesk(state): DeskData | undefined {
        return state.desk
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cardResults(state): Dictionary<string> {
        return state.cardResults
    },
    deskInfoKey(state): number {
        return state.deskInfoKey
    }
}

export const desk: Module<DeskState, RootState> = {
    state,
    mutations,
    actions,
    getters
}
