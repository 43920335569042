import { BetHistoryParams } from '@/datamodel/urlparams/BetHistoryParams'
import { BetLogDetailsParams } from '@/datamodel/urlparams/BetLogDetailsParams'
import { ChangePassParams } from '@/datamodel/urlparams/ChangePassParams'
import { LoginParam } from '@/datamodel/urlparams/LoginParams'
import { LogoutParams } from '@/datamodel/urlparams/LogoutParams'
import service from '@/utils/request'
import { AxiosPromise } from 'axios'

export function login(model: LoginParam): AxiosPromise<string> {
    return service({
        method: 'POST',
        params: model
    })
}

export function betHistory(model: BetHistoryParams): AxiosPromise<string> {
    return service({
        method: 'POST',
        params: model
    })
}

export function changePassword(model: ChangePassParams): AxiosPromise<string> {
    return service({
        method: 'POST',
        params: model
    })
}

export function betHistoryDetails(
    model: BetLogDetailsParams
): AxiosPromise<string> {
    return service({
        method: 'POST',
        params: model
    })
}

export function logout(model: LogoutParams): AxiosPromise<string> {
    return service({
        method: 'POST',
        params: model
    })
}
