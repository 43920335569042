import { createStore, Store, useStore as baseUseStore } from 'vuex'
import { app } from './module/app'
import { user, UserMutation } from './module/user'
import { desk } from './module/desk'
import { loby } from './module/loby'

import Cookies from 'js-cookie'
import { Token } from '@/utils/constants'
import { TableLimit } from '@/datamodel/types'
import { InjectionKey } from 'vue'
import { RootState } from './types'

export const key: InjectionKey<Store<RootState>> = Symbol()

export const store = createStore<RootState>({
    modules: {
        app,
        user,
        desk,
        loby
    },
    mutations: {
        [UserMutation.DELETE_SESSION](state) {
            Cookies.remove(Token.USERNAME)
            Cookies.remove(Token.SESSION)
            Cookies.remove(Token.BET_COIN)
            Cookies.remove(Token.CHIPS_VALUES)
            Cookies.remove(Token.CURRENT_CHIP_INDEX)
            Cookies.remove(Token.CUSTOM_CHIP_VALUE)
            // Cookies.remove(Token.DESK_CURRENT)
            Cookies.remove(Token.DESK_NOTICE)
            Cookies.remove(Token.DESK_LIMIT)
            Cookies.remove(Token.LIMITS)
            Cookies.remove(Token.LOBY_NOTICE)

            state.user.sessionId = undefined
            state.user.userId = undefined
            state.user.betCoin = 0

            state.app.customChipValue = 0
            state.app.currentChipIndex = -1
            state.desk.chipsValues = new Array<number>()
            // state.desk.desk = undefined
            state.desk.notice = ''
            state.desk.deskLimit = undefined
            state.loby.deskLimits = new Array<TableLimit>()
            state.loby.notice = ''
        }
    }
})

// define your own `useStore` composition function
export function useStore(): Store<RootState> {
    return baseUseStore(key)
}
