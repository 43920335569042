import { ACT } from '@/utils/constants'
import { BaseParams } from './BaseParams'

export class DeskMoreInfoModel extends BaseParams {
    desk?: number
    xianId = 0
    constructor(deskId: number) {
        super(ACT.GET_NOTICE_AND_LIMIT)
        this.desk = deskId
    }
}
