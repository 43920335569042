import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Loby from '../views/loby/index.vue'

export enum ROUTES {
    LOBY = 'loby',
    SERVER_SELECTION = 'select-server-line',
    LOGIN = 'login',
    DESK = 'game'
}

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'loby',
        component: Loby
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login/index.vue')
    },
    {
        path: '/game',
        name: 'game',
        component: () => import('@/views/desk/index.vue')
    },
    {
        path: '/select-server-line',
        name: ROUTES.SERVER_SELECTION,
        component: () => import('@/views/server-selection.vue')
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router
